<template>
  <ClienteProveedorLayout
    titulo="Administración - Proveedores"
    tipo="proveedor"
    :service="proveedorService"
  />
</template>

<script>
import ClienteProveedorLayout from "./ClienteProveedorLayout.vue";
import { ProveedorService } from "../services/index";

export default {
  name: "ProveedorLayout",
  components: {
    ClienteProveedorLayout,
  },
  data() {
    return {
      proveedorService: ProveedorService,
    };
  },
};
</script>

<style>
</style>
